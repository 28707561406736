import React from 'react';
import { CROSS_PLATFORM } from '../../config/platforms';

export default function HeaderCopy() {
  return {
    documentation: {
      dropdown: [
        {
          header: 'SDKs',
        },
        {
          title: 'WebViewer SDK',
          mobileTitle: 'Web',
          value: 'web',
        },
        {
          title: 'Server SDK',
          mobileTitle: <span>Server SDK</span>,
          value: 'core',
          link: '/documentation/core/guides/get-started/',
        },
        {
          title: 'Android SDK',
          mobileTitle: 'Android',
          value: 'android',
        },
        {
          title: 'iOS SDK',
          mobileTitle: 'iOS',
          value: 'ios',
        },
        {
          header: 'Frameworks',
        },
        {
          title: 'React',
          mobileTitle: 'React',
          value: 'React',
          link: '/documentation/web/get-started/react/',
        },
        {
          title: 'Angular',
          mobileTitle: 'Angular',
          value: 'Angular',
          link: '/documentation/web/get-started/angular/new-project/',
        },
        {
          title: 'Vue',
          mobileTitle: 'Vue',
          value: 'Vue',
          link: '/documentation/web/get-started/vue/',
        },
        {
          title: 'Xamarin',
          mobileTitle: 'Xamarin',
          value: 'xamarin',
        },
        {
          title: '.NET Framework',
          mobileTitle: '.NET Framework',
          value: 'dotnet',
        },
        {
          title: '.NET Core',
          mobileTitle: '.NET Core',
          value: 'dotnetcore',
        },
        {
          title: 'Node.js',
          mobileTitle: 'Node.js',
          value: 'nodejs',
        },
        {
          title: 'Electron',
          mobileTitle: 'Electron',
          value: 'electron',
          link: '/documentation/web/electron/',
        },
        {
          title: 'React Native',
          mobileTitle: 'React Native',
          value: 'react-native',
          link: '/documentation/guides/react-native/',
        },
        {
          title: 'Flutter',
          mobileTitle: 'Flutter',
          value: 'flutter',
          link: '/documentation/guides/flutter/',
        },
        {
          title: 'UWP',
          mobileTitle: 'UWP',
          value: 'uwp',
        },
        {
          header: 'Integrations',
        },
        {
          title: 'Salesforce',
          mobileTitle: 'Salesforce',
          value: 'salesforce',
          link: '/documentation/salesforce/',
        },
        {
          title: 'Appian',
          mobileTitle: 'appian',
          value: 'appian',
          link: '/documentation/appian/',
        },
        {
          title: 'Mendix',
          mobileTitle: 'mendix',
          value: 'mendix',
          link: '/documentation/mendix/',
        },
        {
          title: 'Outsystems',
          mobileTitle: 'outsystems',
          value: 'outsystems',
          link: '/documentation/outsystems/',
        },
        {
          title: 'SharePoint',
          mobileTitle: 'sharepoint',
          value: 'sharepoint',
          link: '/documentation/sharepoint/',
        },
        {
          header: 'Languages',
        },
        {
          title: 'Java',
          mobileTitle: 'Java',
          value: 'java',
          link: '/documentation/core/guides/get-started/java/',
        },
        {
          title: 'C++',
          mobileTitle: 'C++',
          value: 'cpp',
          link: '/documentation/core/guides/get-started/cpp/',
        },
        {
          title: 'PHP',
          mobileTitle: 'PHP',
          value: 'php',
          link: '/documentation/core/guides/get-started/php/',
        },
        {
          title: 'Python',
          mobileTitle: 'Python',
          value: 'python',
          link: '/documentation/core/guides/get-started/python/',
        },
        {
          title: 'Ruby',
          mobileTitle: 'Ruby',
          value: 'ruby',
          link: '/documentation/core/guides/get-started/ruby/',
        },
        {
          title: 'Go',
          mobileTitle: 'Go',
          value: 'go',
          link: '/documentation/core/guides/get-started/go/',
        },
        {
          title: 'C Interface binding',
          mobileTitle: 'C Interface binding',
          value: 'c-interface-binding',
          link: '/documentation/core/guides/get-started/c-interface-binding/',
        },
        {
          header: 'Resources',
        },
        {
          title: 'Tools & Utilities',
          mobileTitle: 'Tools & Utilities',
          value: 'cli',
        },
        {
          title: 'Cross-Platform API',
          mobileTitle: <span>Cross Platform API</span>,
          link: '/documentation/guides/get-started/',
          value: CROSS_PLATFORM,
        },
      ],
      buttons: {
        web: {
          buttons: [
            { title: 'Guides', to: '/documentation/web/guides/' },
            { title: 'Pricing', to: 'https://apryse.com/pricing/webviewer?docLink=hdrWVprice' },
            { title: 'Downloads', to: '/documentation/web/download/' },
            { title: 'FAQ', to: '/documentation/web/faq/', type: 'faq' },
            { title: 'API', to: '/api/web/index.html' },
            { title: 'Samples', to: '/documentation/web/samples/viewing/' },
            { title: 'Changelog', to: '/documentation/web/changelog/' },
            { title: 'Demo', to: 'https://showcase.apryse.com?docLink=hdrShow' },
          ],
        },
        ios: {
          buttons: [
            { title: 'Guides', to: '/documentation/ios/guides/' },
            { title: 'Downloads', to: '/documentation/ios/download/' },
            { title: 'FAQ', to: '/documentation/ios/faq/', type: 'faq' },
            { title: 'API', to: '/api/ios/index.html' },
            { title: 'Samples', to: '/documentation/samples/?platforms=ios' },
            { title: 'Changelog', to: '/documentation/ios/changelog/' },
            {
              title: 'Demo',
              to: '/documentation/ios/guides/getting-started/run-sample/#showcase-app',
            },
          ],
        },
        android: {
          buttons: [
            { title: 'Guides', to: '/documentation/android/guides/' },
            { title: 'Downloads', to: '/documentation/android/download/' },
            { title: 'FAQ', to: '/documentation/android/faq/', type: 'faq' },
            {
              title: 'API',
              to: '/api/android/javadoc/reference/packages.html',
            },
            {
              title: 'Samples',
              to: '/documentation/samples/?platforms=android',
            },
            { title: 'Changelog', to: '/documentation/android/changelog/' },
            {
              title: 'Demo',
              to: '/documentation/android/guides/getting-started/sample-showcase/',
            },
          ],
        },
        xamarin: {
          buttons: [
            { title: 'Guides', to: '/documentation/xamarin/guides/' },
            { title: 'FAQ', to: '/documentation/xamarin/faq/', type: 'faq' },
            { title: 'API', to: '/documentation/xamarin/api/' },
            {
              title: 'Samples',
              to: '/documentation/samples/?platforms=xamarin',
            },
            { title: 'Changelog', to: '/documentation/xamarin/changelog/' },
          ],
        },
        mac: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?platform=mac' },
            { title: 'Downloads', to: '/documentation/core/download/?platform=mac' },
            { title: 'FAQ', to: '/documentation/mac/faq/', type: 'faq' },
            { title: 'API', to: '/documentation/mac/api/' },
            { title: 'Samples', to: '/documentation/samples/?platforms=mac' },
            { title: 'Changelog', to: '/documentation/mac/changelog/' },
          ],
        },
        windows: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?platform=windows' },
            { title: 'Downloads', to: '/documentation/core/download/?platform=windows' },
            { title: 'FAQ', to: '/documentation/windows/faq/', type: 'faq' },
            { title: 'API', to: '/documentation/windows/api/' },
            {
              title: 'Samples',
              to: '/documentation/samples/?platforms=windows',
            },
            { title: 'Changelog', to: '/documentation/windows/changelog/' },
          ],
        },
        uwp: {
          buttons: [
            { title: 'Guides', to: '/documentation/uwp/guides/' },
            { title: 'FAQ', to: '/documentation/uwp/faq/', type: 'faq' },
            { title: 'API', to: '/documentation/uwp/api/' },
            { title: 'Samples', to: '/documentation/samples/?platforms=uwp' },
            { title: 'Changelog', to: '/documentation/uwp/changelog/' },
          ],
        },
        linux: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?platform=linux' },
            { title: 'Downloads', to: '/documentation/core/download/?platform=linux' },
            { title: 'FAQ', to: '/documentation/linux/faq/', type: 'faq' },
            { title: 'API', to: '/documentation/linux/api/' },
            { title: 'Samples', to: '/documentation/samples/?platforms=linux' },
            { title: 'Changelog', to: '/documentation/linux/changelog/' },
          ],
        },
        cli: {
          buttons: [{ title: 'Guides', to: '/documentation/cli/guides/' }],
        },
        core: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/' },
            { title: 'Downloads', to: '/documentation/core/download/' },
            { title: 'FAQ', to: '/documentation/core/faq/' },
            { title: 'API', to: '/documentation/core/api/' },
            { title: 'Samples', to: '/documentation/samples/' },
            { title: 'Changelog', to: '/documentation/core/changelog/' },
          ],
        },
        'cross-platform': {
          buttons: [
            { title: 'Samples', to: '/documentation/samples/' },
            { title: 'Demo', to: 'https://showcase.apryse.com' },
          ],
        },
        dotnet: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=dotnet' },
            { title: 'FAQ', to: '/documentation/dotnet/faq/', type: 'faq' },
            { title: 'API', to: '/api/PDFTronSDK/dotnet/index.html' },
            { title: 'Samples', to: '/documentation/samples/?platforms=dotnet' },
            { title: 'Changelog', to: '/documentation/dotnet/changelog/' },
          ],
        },
        dotnetcore: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=dotnetcore' },
            { title: 'FAQ', to: '/documentation/dotnetcore/faq/', type: 'faq' },
            { title: 'API', to: '/api/PDFTronSDK/dotnetcore/index.html' },
            {
              title: 'Samples',
              to: '/documentation/samples/?platforms=dotnetcore',
            },
            { title: 'Changelog', to: '/documentation/dotnetcore/changelog/' },
          ],
        },
        nodejs: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=nodejs' },
            { title: 'FAQ', to: '/documentation/nodejs/faq/', type: 'faq' },
            { title: 'Samples', to: '/documentation/samples/?platforms=nodejs' },
            { title: 'API', to: '/api/pdfnet-node/PDFNet.html' },
            { title: 'Changelog', to: '/documentation/nodejs/changelog/' },
          ],
        },
        java: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=java' },
            { title: 'FAQ', to: '/documentation/java/faq/', type: 'faq' },
            { title: 'API', to: '/api/PDFTronSDK/java/packages.html' },
            { title: 'Samples', to: '/documentation/samples/?platforms=java' },
            { title: 'Changelog', to: '/documentation/java/changelog/' },
          ],
        },
        cpp: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=cpp' },
            { title: 'FAQ', to: '/documentation/cpp/faq/', type: 'faq' },
            { title: 'API', to: '/api/PDFTronSDK/cpp/index.html' },
            { title: 'Samples', to: '/documentation/samples/?platforms=cpp' },
            { title: 'Changelog', to: '/documentation/cpp/changelog/' },
          ],
        },
        php: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=php' },
            { title: 'FAQ', to: '/documentation/php/faq/', type: 'faq' },
            { title: 'Samples', to: '/documentation/samples/?platforms=php' },
            { title: 'Changelog', to: '/documentation/php/changelog/' },
          ],
        },
        python: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=python' },
            { title: 'FAQ', to: '/documentation/python/faq/', type: 'faq' },
            { title: 'API', to: '/api/PDFTronSDK/python/apryse_sdk.html' },
            { title: 'Samples', to: '/documentation/samples/?platforms=python' },
            { title: 'Changelog', to: '/documentation/python/changelog/' },
          ],
        },
        ruby: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=ruby' },
            { title: 'FAQ', to: '/documentation/ruby/faq/', type: 'faq' },
            { title: 'Samples', to: '/documentation/samples/?platforms=ruby' },
            { title: 'Changelog', to: '/documentation/ruby/changelog/' },
          ],
        },
        go: {
          buttons: [
            { title: 'Guides', to: '/documentation/core/guides/?language=go' },
            { title: 'FAQ', to: '/documentation/go/faq/', type: 'faq' },
            { title: 'API', to: 'https://pkg.go.dev/github.com/ApryseSDK/pdftron-go/v2#section-documentation' },
            { title: 'Samples', to: '/documentation/samples/?platforms=go' },
            { title: 'Changelog', to: '/documentation/go/changelog/' },
          ],
        },
        salesforce: {
          buttons: [
            { title: 'Guides', to: '/documentation/salesforce/guides/' },
          ],
        },
        appian: {
          buttons: [
            { title: 'Guides', to: '/documentation/appian/guides/' },
            { title: 'FAQ', to: '/documentation/appian/faq/', type: 'faq' },
            { title: 'Component Plugin', to: 'https://community.appian.com/b/appmarket/posts/apryse-webviewer-component' },
            { title: 'Connected System Plugin', to: 'https://community.appian.com/b/appmarket/posts/apryse-webviewer-connected-system' },
          ],
        },
        mendix: {
          buttons: [
            { title: 'Mendix Widget', to: 'https://marketplace.mendix.com/link/component/121024' },
            { title: 'Mendix Module', to: 'https://marketplace.mendix.com/link/component/202600' },
          ]
        },
        outsystems: {
          buttons: [
            { title: 'OutSystems Forge', to: 'https://www.outsystems.com/forge/component-overview/11377/pdftron' },
          ]
        },
        sharepoint: {
          buttons: [{ title: 'Guides', to: '/documentation/sharepoint/' }],
        },
      },
    },
  };
}
